<template>
    <ConsolidacionNotas/>
</template>
<script>
import ConsolidacionNotas from "@/components/ConsolidacionNotas.vue";
export default {
    components: {
    ConsolidacionNotas,
  },
}
</script>